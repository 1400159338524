import { Tooltip, Typography } from "@mui/material";
import { dcolors } from "corexxx";
import { CSSProperties } from "react";
import { useDAppCommand } from "./DAppCommand";
import { DCol } from "./DCol";
export const DText = ({
    children,
    title,
    style,
    mode,
    id,
    onClick,
    dark
}: {
    id?: string;
    children: any;
    style?: CSSProperties;
    title?: string
    mode?: "title" | "subtitle" | "topper" | "page_subheader" | "footnote";
    onClick?: () => void,
    dark?: boolean
}) => {
    let appCommand = useDAppCommand()
    let style1: CSSProperties = { color: (appCommand?.state.appConfig?.dark || dark) ? "white" : dcolors.pallets.grey700 };
    switch (mode) {
        case "title":
            style1 = { ...style1, fontWeight: "bold", fontSize: 20 };
            break;
        case "page_subheader":
            style1 = { ...style1, fontWeight: "bold", fontSize: 17.5, textTransform: "uppercase", marginBottom: 16 };
            break;
        case "subtitle":
            style1 = { ...style1, fontSize: 15, ...style1 };
            break;
        case "topper":
            style1 = { ...style1, textTransform: "uppercase", fontSize: 13.5, color: dcolors.pallets.grey400, ...style1 };
            break;
        case "footnote":
            style1 = { ...style1, color: dcolors.pallets.grey600, marginTop: 10, fontSize: 10, textAlign: "center" };
    }
    return (
        <Tooltip title={title}>
            <Typography id={id} style={{ padding: 0, verticalAlign: 'center', ...style1, ...style }} onClick={onClick} title={title}>
                {children || "---"}
            </Typography>
        </Tooltip>
    );
};

export const DTextTwo = ({ first, second, style }: { first: string, second: string, style?: CSSProperties }) => {

    const style1: CSSProperties = { textAlign: 'center' }
    const style2: CSSProperties = { textAlign: 'center', fontWeight: 'bold', fontSize: 23 }
    return (<DCol style={{ ...style }}>
        <DText style={style1}>{first}</DText>
        <DText style={style2}>{second}</DText>
    </DCol>)
}

export const DToDoView = () => {
    return <DText style={{ padding: 20, textAlign: 'center' }}>TODO - This is a place holder will be TODO</DText>
}
