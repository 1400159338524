import CloseIcon from '@mui/icons-material/Close';
import { Dialog, IconButton, Slide } from "@mui/material";
import { TransitionProps } from '@mui/material/transitions';
import { TVoidCalBack } from "corexxx";
import React, { CSSProperties } from 'react';
import { DTextWithControl } from '../../algoverse/pages/Common';
import { DCol } from "./DCol";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});


export function DFullScreenDialog({ open, onClose, content, style, title }: { title?: string, open: boolean; onClose: TVoidCalBack; content: JSX.Element, style?: CSSProperties }) {
    return (
        <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition} style={{ marginLeft: 0, ...style, }}>
            <DCol style={{ position: 'relative' }}>
                <DTextWithControl style={{ background: 'black', padding: 10 }} dark text={title || 'Dialog Content'} rightJsx={
                    <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close" style={{ color: 'white' }}>
                        <CloseIcon />
                    </IconButton>
                } />
                {content}
            </DCol>
        </Dialog>
    );
}