
import { dcolors } from 'corexxx';
import React from 'react';
import { DHook } from '../../libs/common_hooks/DHook';
import { useDAppCommand } from '../../libs/dweb/DAppCommand';
import { DCol } from '../../libs/dweb/DCol';
import { DImage } from '../../libs/dweb/DImage';
import { DPageRootLayoutWithDrawer } from '../../libs/dweb/DPageRootLayout';
import { DText } from '../../libs/dweb/DText';
import { useDemo } from '../models/DemoHook';
import { AdminSummaryPage } from './AdminSummaryPage';
import { GraphsPage } from './GraphsPage';
import { ImageAlignPage } from './ImageAlignPage';
import { ImageViewPage } from './ImageViewPage';
import { RepairPage } from './RepairPage';
import { StatisticsPage } from './StatisticsPage';

export function DashBoardWrapper({ children }: { children: any }) {
    return <div style={{ marginTop: 10, width: '100%', height: '100%' }}>{children}</div>
}

export function DashBoardPage() {
    let hook = useDemo()
    let appCommand = useDAppCommand()
    let [menu, setMenu] = React.useState([
        {
            name: 'Image View Page',
            screen: <DashBoardWrapper><ImageViewPage /></DashBoardWrapper>
        },
    ])


    React.useEffect(() => {
        hook.api.loadManifest()
    }, [])

    React.useEffect(() => {
        if (appCommand.state.accountState?.permission_group?.includes('GRODOK_CRACKS_ADMIN')) {
            setMenu([...menu, {
                name: 'AdminPage',
                screen: <DashBoardWrapper><AdminSummaryPage /></DashBoardWrapper>
            },
            {
                name: 'Statistics Page',
                screen: <DashBoardWrapper><StatisticsPage /></DashBoardWrapper>
            },
            {
                name: 'Graph Page',
                screen: <DashBoardWrapper><GraphsPage /></DashBoardWrapper>
            },

            {
                name: 'Repair Page',
                screen: <DashBoardWrapper><RepairPage /></DashBoardWrapper>
            },
            {
                name: 'Customer Page',
                screen: <ImageAlignPage />
            },
            ])
        }
        if (appCommand.state.accountState?.permission_group?.includes('GRODOK_CRACKS_PARTNERS')) {
            setMenu([...menu, {
                name: 'Statistics Page',
                screen: <DashBoardWrapper><StatisticsPage /></DashBoardWrapper>
            },
            {
                name: 'Customer Page',
                screen: <ImageAlignPage />
            },
            {
                name: 'Graph Page',
                screen: <DashBoardWrapper><GraphsPage /></DashBoardWrapper>
            },

            {
                name: 'Repair Page',
                screen: <DashBoardWrapper><RepairPage /></DashBoardWrapper>
            }])
        }


    }, [appCommand.state.accountState])

    DHook.useMountLogger("DashBoardPage")
    return (
        <DPageRootLayoutWithDrawer
            bodyStyle={{ backgroundColor: dcolors.pallets.yellow50 }}
            config={{
                title: '',
            }}
            drawerOpen={true}
            drawerPreset={{
                drawerWidth: 300,
                headerText: " ",
                headerExtra: (<>
                    <DCol style={{ alignContent: 'center', alignItems: 'center', }}>
                        <DImage src={require("../assart/img/GC.png")} style={{ width: 200, height: 200 }} />
                        <DText style={{ fontSize: 20, color: 'white' }}>Grodok Crack Detection</DText>
                    </DCol>
                </>),
                footerText: "Grodok.com @2022",
                drawerBackground: "#e7e7e7",
                color: "black",
                menuList: menu
            }}

        ></DPageRootLayoutWithDrawer>)
}