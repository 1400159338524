import { RefreshOutlined } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";
import { TVoidCalBack } from "corexxx";
import { CSSProperties, useState } from "react";

export function DRefreshButton({ onClick, style }: { onClick: TVoidCalBack, style?: CSSProperties }) {
    let [loading, setLoading] = useState(false)
    return <IconButton style={style} onClick={async () => {
        setLoading(true)
        try {
            await onClick()
        } catch (e) {

        }
        setLoading(false)
    }}>
        {loading ? (<CircularProgress size={20} />) : (<RefreshOutlined />)}
    </IconButton>
}