import { CSSProperties } from "react";
import { OnePager, TOnePagerConfig } from "../../libs/components/simpleonepager/OnePager";
import { PageHeaderMenu } from "../../libs/dweb/DPageHeaderMenu";
export const example: TOnePagerConfig = {
  hero_section: {
    hero_title: 'Detect Structrual Abnormalities Using High Res Drone Images and AI',
    hero_subtitle: 'Cutting-edge technology for pinpoint accuracy in damage detection',
    hero_images_list: [
      require('../assart/img/1.png'),
      require('../assart/img/2.png'),
      require('../assart/img/3.png')
    ],
  },

  generic_section: [

    {
      groupOfThree: [
        {
          title: 'High-Tech Drone Scanning',
          subtitle: 'Our drones soar high to capture optical and thermal images of your building, ensuring no crack or damage goes unnoticed. We use advanced aerial technology to provide a comprehensive overview of your structure’s integrity.',
          btn_text: 'High Def Img',
          btn_link: '/new'
        },
        {
          title: 'State-of-the-Art AI Analysis',
          subtitle: 'With our state-of-the-art AI, we meticulously detect, measure, and locate every crack and weak spot. Our intelligent algorithms do the heavy lifting, offering you precise and reliable results you can trust.',
          btn_text: 'AI is Here',
          btn_link: '/new'
        },
        {
          title: 'Quick and Easy Signup',
          subtitle: 'Join us and experience the future of building inspections. With a simple signup process, you can start safeguarding your property today. Let us help you maintain a safe and sound structure effortlessly.',
          btn_text: 'Get Us',
          btn_link: '/new'
        },
      ]
    }],
  footer_section: {
    author_text: 'Grodok',
    author_email: 'mailto:hello@grodok.com',
    author_link: 'https://www.linkedin.com/company/grodok/',
    privacy_link: 'https://www.linkedin.com/company/grodok/',
    toc_link: 'https://www.linkedin.com/company/grodok/',
  },
  bodyStyle: { background: '#240b36' },
  containerStyle: { marginTop: 0 },
  footerStyle: {
    background: '#071d33'
  },
  heroStyle: {
    padding: '0px 0',
    backgroundImage: 'linear-gradient(-180deg, #240b36 50%, #c31432 90%)'
  },
  featureStyle: {
    background: '#c31432'
  }
}
export const CrackLandingPage = ({ style }: { style?: CSSProperties }) => {
  return (<div style={{ background: '#240b36' }}>
    <PageHeaderMenu logoStyle={{ maxHeight: '50px' }} logo={require('../assart/img/GC.png')} rightDropDownActions={[
      { text: 'Go To Dashboard', href: '/dashboard' }]} />
    <OnePager config={example} />
  </div>)
}
