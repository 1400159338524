import { CSSProperties } from "react";
import { DCol } from "./DCol";
import { DRow } from "./DRow";
import { DText } from "./DText";


export function DTextDashboard({ item, dark, itemStyle, textStyle, valueStyle }: {
    itemStyle?: CSSProperties, textStyle?: CSSProperties, valueStyle?: CSSProperties,
    item: { text: string, value: string, sub_text?: string }[], dark?: boolean
}) {
    return <DRow>
        {item.map((it, idx) => <DTextDashboardItem key={idx}
            dark={dark}
            text={it.text}
            value={it.value}
            sub_text={it.sub_text}
            itemStyle={itemStyle}
            textStyle={textStyle}
            valueStyle={valueStyle}
        />)}
    </DRow>
}

export function DTextDashboardItem({ text, value, itemStyle, textStyle, valueStyle, sub_text, dark }: {
    itemStyle?: CSSProperties, textStyle?: CSSProperties, valueStyle?: CSSProperties,
    text: string, value: string, sub_text?: string, dark?: boolean
}) {
    return <DCol style={{ padding: 0, ...itemStyle }}>
        <DText dark={dark} style={{ marginBottom: 4, textTransform: 'uppercase', fontSize: 12, ...textStyle }}>{text}</DText>
        <DText dark={dark} style={{ fontSize: 20, fontWeight: 'bold', ...valueStyle }}>{value}</DText>
        {sub_text ? <DText dark={dark} style={{ fontSize: 13, fontWeight: 'normal' }}>{sub_text}</DText> : null}
    </DCol>
}