import { dnetwork, TObject } from "corexxx";
import { atom, useRecoilState } from "recoil";
import { useDAppCommand } from "../../libs/dweb/DAppCommand";
import { extractNumber } from "../pages/AdminSummaryPage";

export type TImageMetaInformation = {
    project_name: string,
    _id: string,
    phase: string,
    image_name: string,
    image_hash: string,
    is_processed: boolean,
    crack_detected: boolean,
    crack_annotation: []
}
export type TFloorMap = {
    floor: number,
    range: [number, number]
}

export type TNetworkProjectConfig = {
    project_name: string,
    _id: string,
    phase_list: {
        [key: string]: {
            number_of_column: number,
            floor_mapping: TFloorMap[],
        }
    }
}

export type TProjectInfoSummary = {
    name: string,
    phaseList: {
        [key: string]: {
            name: string,
            number_of_column: number,
            floor_mapping: TFloorMap[],
            imgList: TImageMetaInformation[]
        };
    },
    total_image: number,
    image_with_crash_count: number,
    total_crack_count: number,
    total_process_count: number
}

export type TProjectInfo = {
    [key: string]: TProjectInfoSummary
}


const ProjectInfoAtom = atom<TProjectInfo>({
    key: "ProjectInfoAtom",
    default: {},
});

const RawImageManifestAtom = atom<TImageMetaInformation[] | []>({
    key: "RawImageManifestAtom",
    default: [],
});

export const useDemo = () => {
    const appCommand = useDAppCommand(); // app command 
    let [rawImageManifest, setRawImageManifest] = useRecoilState(RawImageManifestAtom);
    let [projectInfo, setProjectInfo] = useRecoilState(ProjectInfoAtom);

    async function reload() {
        appCommand.api.safeCall(async () => {
            let resp = await appCommand.api.getSimpleStore(`${appCommand.state.simpleStoreEndpoint}/api/remote_secure_nodel_grodok_cracks_project_config`)
            let configList = resp.out as TNetworkProjectConfig[]
            if (configList.length == 0) {
                appCommand.setNotification({ type: 'error', msg: "You have no project to view. Please talk to admin" })
                return;
            }
            let firstProject = configList[0]
            let resp1 = await dnetwork.getSimpleStore(`${appCommand.state.simpleStoreEndpoint}/api/remote_nodel_grodok_cracks?_limit=5000&project_name=${firstProject.project_name}`)
            let allImages = resp1.out as TImageMetaInformation[]
            setRawImageManifest(allImages)
            setProjectInfo(convert(allImages, firstProject))
        })
    }

    return {
        api: {
            setRawImageManifest: setRawImageManifest,
            loadManifest: reload,
            updateInformation: async (id: string, data: TObject) => {
                await appCommand.api.postSimpleStore(`/api/remote_nodel_grodok_cracks/update`, {
                    _id: id,
                    ...data
                })
            }
        },
        state: {
            rawImageManifest: rawImageManifest,
            projectInfo: projectInfo
        }
    }
}



// helper 
function convert(data: TImageMetaInformation[], config: TNetworkProjectConfig): TProjectInfo {
    let obj: TProjectInfo = {}

    data.forEach(entry => {
        if (!obj[entry.project_name]) {
            obj[entry.project_name] = {
                name: entry.project_name,
                phaseList: {},
                total_image: 0,
                image_with_crash_count: 0,
                total_crack_count: 0,
                total_process_count: 0
            }
        }
        let p_entry = obj[entry.project_name]

        if (!p_entry.phaseList[entry.phase]) {
            p_entry.phaseList[entry.phase] = {
                name: entry.phase,
                imgList: [],
                number_of_column: config?.phase_list?.[entry.phase]?.number_of_column || 0,
                floor_mapping: config?.phase_list?.[entry.phase]?.floor_mapping || [],
            }
        }

        p_entry.phaseList[entry.phase].imgList.push(entry)
        p_entry.total_image += 1
        p_entry.total_crack_count += entry.crack_annotation?.length || 0
        p_entry.total_process_count += entry.is_processed ? 1 : 0
        p_entry.image_with_crash_count += (entry.crack_annotation?.length || 0) > 0 ? 1 : 0
    })

    // shorting
    Object.values(obj).forEach(proj => {
        Object.values(proj.phaseList).forEach(phase => {
            phase.imgList.sort((a, b) => extractNumber(a.image_name) > extractNumber(b.image_name) ? 1 : -1)
        })
    })
    return obj
}