import { dcolors } from "corexxx";
import { useState, useEffect, useCallback } from "react";
import { DA4Page } from "../../libs/dweb/DA4Page";
import { DResponsiveWindow } from "../../libs/dweb/DResponsiveWindow";
import { DRow } from "../../libs/dweb/DRow";
import { DTextDashboard } from "../../libs/dweb/DTextDashboard";
import { useDemo } from "../models/DemoHook";
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";


interface FloorCrackData {
    totalCracks: number;
    totalArea: number;
}

interface PhaseCrackData {
    [phase: string]: FloorCrackData;
}

interface FloorCrackInfo {
    [floor: number]: PhaseCrackData;
}

export function extractNumber(filename: string) {
    let match = filename.match(/^(\d+)/);
    return match ? parseInt(match[0], 10) : Infinity;
}

function calculatePolygonArea(points: { x: number, y: number }[]): number {
    let area = 0;
    const n = points.length;
    for (let i = 0; i < n; i++) {
        const { x: x1, y: y1 } = points[i];
        const { x: x2, y: y2 } = points[(i + 1) % n];
        area += (x1 * y2 - x2 * y1);
    }
    return Math.abs(area / 2) * 0.03 * 0.03 / 144;
}

export function StatisticsPage() {
    let hook = useDemo();
    let [curProject, setCurProject] = useState<string>('');

    useEffect(() => {
        let keys = Object.keys(hook.state.projectInfo);
        if (keys.length > 0) {
            setCurProject(keys[0]);
        }
    }, [hook.state.projectInfo]);

    const getCrackDataPerFloor = useCallback(() => {
        const projectInfo = hook.state.projectInfo[curProject];
        if (!projectInfo) return {};

        const crackDataPerFloor: FloorCrackInfo = {};

        Object.entries(projectInfo.phaseList).forEach(([phaseName, phase]) => {
            phase.imgList.forEach(image => {
                if (image.crack_detected && image.crack_annotation.length > 0) {
                    const imageNumber = extractNumber(image.image_name);
                    const floorMap = phase.floor_mapping.find(floorMap => imageNumber >= floorMap.range[0] && imageNumber <= floorMap.range[1]);

                    if (floorMap) {
                        const floor = floorMap.floor;
                        if (!crackDataPerFloor[floor]) {
                            crackDataPerFloor[floor] = {};
                        }
                        if (!crackDataPerFloor[floor][phaseName]) {
                            crackDataPerFloor[floor][phaseName] = { totalCracks: 0, totalArea: 0 };
                        }
                        const totalCrackArea = image.crack_annotation.reduce((sum, annotation: { points: { x: number, y: number }[] }) => {
                            return sum + calculatePolygonArea(annotation.points);
                        }, 0);
                        crackDataPerFloor[floor][phaseName].totalCracks += image.crack_annotation.length;
                        crackDataPerFloor[floor][phaseName].totalArea += totalCrackArea;
                    } else {
                        console.warn(`No floor mapping found for image: ${image.image_name} in phase: ${phaseName}`);
                    }
                }
            });

            phase.floor_mapping.forEach(floorMap => {
                const floor = floorMap.floor;
                if (!crackDataPerFloor[floor]) {
                    crackDataPerFloor[floor] = {};
                }
                if (!crackDataPerFloor[floor][phaseName]) {
                    crackDataPerFloor[floor][phaseName] = { totalCracks: 0, totalArea: 0 };
                }
            });
        });

        return crackDataPerFloor;
    }, [curProject, hook.state.projectInfo]);

    const crackDataPerFloor = getCrackDataPerFloor();

    const totalCrackArea = Object.values(crackDataPerFloor).reduce((total: number, phaseData) => {
        return total + Object.values(phaseData as PhaseCrackData).reduce((phaseTotal: number, data: FloorCrackData) => {
            return phaseTotal + data.totalArea;
        }, 0);
    }, 0);


    const sortedFloors = Object.keys(crackDataPerFloor).map(Number).sort((a, b) => a - b);
    const phases = Object.keys(hook.state.projectInfo[curProject]?.phaseList || {});

    return (
        <DResponsiveWindow>
            <DRow style={{ flex: 0, marginTop: 20, marginBottom: 20 }}>
                {hook.state.projectInfo[curProject] ? (
                    <DTextDashboard
                        dark={true}
                        itemStyle={{ background: dcolors.pallets.blue700, margin: 10, padding: 20 }}
                        valueStyle={{ fontSize: 32 }}
                        item={[
                            {
                                text: 'Project',
                                value: curProject || '',
                            },
                            {
                                text: 'Total Image',
                                value: (hook.state.projectInfo[curProject].total_image || 0) + '',
                            },
                            {
                                text: 'Defective Images',
                                value: `${hook.state.projectInfo[curProject].image_with_crash_count}/${hook.state.projectInfo[curProject].total_image}`,
                                sub_text: `${(
                                    (hook.state.projectInfo[curProject].image_with_crash_count / hook.state.projectInfo[curProject].total_image) *
                                    100
                                ).toFixed(2)}% of the images has crack`,
                            },
                            {
                                text: 'Number of Cracks',
                                value: `${hook.state.projectInfo[curProject].total_crack_count}`,
                            },
                            {
                                text: 'Total Crack Area',
                                value: `${totalCrackArea.toFixed(0)} sq ft`,
                            },
                        ]}
                    />
                ) : null}
            </DRow>
            <DRow>
                <Box sx={{ marginBottom: 4 }}>
                    <Typography variant="h6" sx={{ marginBottom: 2 }}>{`Cracks per Floor`}</Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Floor</TableCell>
                                    {phases.map((phase) => (
                                        <TableCell key={phase}>{`${phase} (Total Cracks)`}</TableCell>
                                    ))}
                                    {phases.map((phase) => (
                                        <TableCell key={phase}>{`${phase} (Total Area sq ft)`}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedFloors.map((floor) => (
                                    <TableRow key={floor}>
                                        <TableCell>{floor}</TableCell>
                                        {phases.map((phase) => (
                                            <TableCell key={`${phase}-cracks`}>
                                                {crackDataPerFloor[floor]?.[phase]?.totalCracks ?? 0}
                                            </TableCell>
                                        ))}
                                        {phases.map((phase) => (
                                            <TableCell key={`${phase}-area`}>
                                                {crackDataPerFloor[floor]?.[phase]?.totalArea?.toFixed(0) ?? 0}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </DRow>
        </DResponsiveWindow>
    );
}


