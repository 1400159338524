// This is a common untils which share between clint and server
export namespace SSUtils {
    // Check if given ISO date is today or not! <<<
    export function isToday(isoString: string): boolean {
        const givenDate = new Date(isoString);
        const today = new Date();
        return givenDate.getUTCFullYear() === today.getUTCFullYear() &&
            givenDate.getUTCMonth() === today.getUTCMonth() &&
            givenDate.getUTCDate() === today.getUTCDate();
    }

    export function toHumanReadFormatDate(isoDate: string): string {
        // Parse the date components from the ISO string
        const date = new Date(isoDate);
        const day = date.getUTCDate();
        const month = date.getUTCMonth(); // Months are 0-indexed
        const year = date.getUTCFullYear();

        // Arrays to get the day of the week and month names
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        // Get the full day of the week name
        const dayOfWeek = daysOfWeek[date.getUTCDay()];

        // Get the month name
        const monthName = months[month];

        // Get the last two digits of the year
        const yearSuffix = year.toString().slice(-2);

        // Function to add the appropriate ordinal suffix to the day number
        function getOrdinalSuffix(day: number): string {
            if (day > 3 && day < 21) return `${day}th`; // Exceptions for 11th to 13th
            switch (day % 10) {
                case 1: return `${day}st`;
                case 2: return `${day}nd`;
                case 3: return `${day}rd`;
                default: return `${day}th`;
            }
        }

        // Format the day with the suffix
        const dayWithSuffix = getOrdinalSuffix(day);

        // Return the formatted string
        return `${dayOfWeek}, ${dayWithSuffix} ${monthName}, ${yearSuffix}`;
    }
}