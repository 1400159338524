import { dcolors } from "corexxx";
import { useState, useEffect, useCallback } from "react";
import { DA4Page } from "../../libs/dweb/DA4Page";
import { DResponsiveWindow } from "../../libs/dweb/DResponsiveWindow";
import { DRow } from "../../libs/dweb/DRow";
import { DTextDashboard } from "../../libs/dweb/DTextDashboard";
import { useDemo } from "../models/DemoHook";
import { Box, Typography } from "@mui/material";
import { DSelectInput } from "../../libs/dweb/DSelectInput";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface FloorCrackData {
    totalCracks: number;
    totalArea: number;
}

interface PhaseCrackData {
    [phase: string]: FloorCrackData;
}

interface FloorCrackInfo {
    [floor: number]: PhaseCrackData;
}

export function extractNumber(filename: string) {
    let match = filename.match(/^(\d+)/);
    return match ? parseInt(match[0], 10) : Infinity;
}

function calculatePolygonArea(points: { x: number, y: number }[]): number {
    let area = 0;
    const n = points.length;
    for (let i = 0; i < n; i++) {
        const { x: x1, y: y1 } = points[i];
        const { x: x2, y: y2 } = points[(i + 1) % n];
        area += (x1 * y2 - x2 * y1);
    }
    // Convert area from square inches to square feet
    return Math.abs(area / 2) * 0.03 * 0.03 / 144;
}

const phaseColors = [
    dcolors.pallets.blue700,
    dcolors.pallets.red700,
    dcolors.pallets.green700,
    dcolors.pallets.orange700,
    dcolors.pallets.purple700,
];

export function GraphsPage() {
    let hook = useDemo();
    let [curProject, setCurProject] = useState<string>('');
    let [curPhase, setCurPhase] = useState<string>('')

    useEffect(() => {
        let keys = Object.keys(hook.state.projectInfo);
        if (keys.length > 0) {
            setCurProject(keys[0]);
        }
    }, [hook.state.projectInfo]);

    const getCrackDataPerPhase = useCallback(() => {
        const projectInfo = hook.state.projectInfo[curProject];
        if (!projectInfo) return [];

        const crackDataPerPhase = Object.entries(projectInfo.phaseList).map(([phaseName, phase]) => {
            let totalCracks = 0;
            let totalArea = 0;
            phase.imgList.forEach(image => {
                if (image.crack_detected) {
                    totalCracks += image.crack_annotation.length;
                    totalArea += image.crack_annotation.reduce((sum, annotation: { points: { x: number, y: number }[] }) => {
                        return sum + calculatePolygonArea(annotation.points);
                    }, 0);
                }
            });
            return { phase: phaseName, totalCracks, totalArea: Math.round(totalArea) };
        });

        return crackDataPerPhase;
    }, [curProject, hook.state.projectInfo]);

    const getCrackDataPerFloor = useCallback(() => {
        const projectInfo = hook.state.projectInfo[curProject];
        if (!projectInfo) return {};

        const crackDataPerFloor: FloorCrackInfo = {};

        Object.entries(projectInfo.phaseList).forEach(([phaseName, phase]) => {
            phase.imgList.forEach(image => {
                if (image.crack_detected && image.crack_annotation.length > 0) {
                    const imageNumber = extractNumber(image.image_name);
                    const floorMap = phase.floor_mapping.find(floorMap => imageNumber >= floorMap.range[0] && imageNumber <= floorMap.range[1]);

                    if (floorMap) {
                        const floor = floorMap.floor;
                        if (!crackDataPerFloor[floor]) {
                            crackDataPerFloor[floor] = {};
                        }
                        if (!crackDataPerFloor[floor][phaseName]) {
                            crackDataPerFloor[floor][phaseName] = { totalCracks: 0, totalArea: 0 };
                        }
                        const totalCrackArea = image.crack_annotation.reduce((sum, annotation: { points: { x: number, y: number }[] }) => {
                            return sum + calculatePolygonArea(annotation.points);
                        }, 0);
                        crackDataPerFloor[floor][phaseName].totalCracks += image.crack_annotation.length;
                        crackDataPerFloor[floor][phaseName].totalArea += totalCrackArea;
                    } else {
                        console.warn(`No floor mapping found for image: ${image.image_name} in phase: ${phaseName}`);
                    }
                }
            });

            // Ensure zero area and count for floors with no cracks in this phase
            phase.floor_mapping.forEach(floorMap => {
                const floor = floorMap.floor;
                if (!crackDataPerFloor[floor]) {
                    crackDataPerFloor[floor] = {};
                }
                if (!crackDataPerFloor[floor][phaseName]) {
                    crackDataPerFloor[floor][phaseName] = { totalCracks: 0, totalArea: 0 };
                }
            });
        });

        return crackDataPerFloor;
    }, [curProject, hook.state.projectInfo]);

    const crackDataPerPhase = getCrackDataPerPhase();
    const crackDataPerFloor = getCrackDataPerFloor();

    const sortedFloors = Object.keys(crackDataPerFloor).map(Number).sort((a, b) => a - b);
    const phases = Object.keys(hook.state.projectInfo[curProject]?.phaseList || {});
    const selectedPhaseData = crackDataPerFloor && curPhase ? Object.entries(crackDataPerFloor).map(([floor, phaseData]) => ({
        floor: parseInt(floor, 10),
        totalCracks: phaseData[curPhase]?.totalCracks || 0,
        totalArea: Math.round(phaseData[curPhase]?.totalArea || 0)
    })) : [];

    return (
        <DResponsiveWindow>
            <DRow>
                <DSelectInput
                    style={{ width: 200, marginRight: 10 }}
                    label="Select Project"
                    value={curProject}
                    options={Object.keys(hook.state.projectInfo).map(x => ({ text: x, value: x }))}
                    onChange={setCurProject}
                />
                <DSelectInput
                    style={{ width: 200, marginRight: 10 }}
                    label="Select Phase"
                    value={curPhase}
                    options={Object.keys(hook.state.projectInfo[curProject]?.phaseList || {}).map(x => ({ text: x, value: x }))}
                    onChange={setCurPhase}
                />
            </DRow>
            {curPhase && (
                <DRow>
                    <Box sx={{ marginBottom: 4, width: '100%', height: 400 }}>
                        <Typography variant="h6" sx={{ marginBottom: 2 }}>{`Total Number of Cracks and Crack Area per Floor for ${curPhase}`}</Typography>
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                                data={selectedPhaseData}
                                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="floor" />
                                <YAxis />
                                <Tooltip formatter={(value: number, name: string) => name === 'totalArea' ? `${value} sq ft` : value} />
                                <Legend formatter={(value: string) => value === 'totalArea' ? 'Total Area (sq ft)' : 'Total Cracks'} />
                                <Bar dataKey="totalCracks" fill={dcolors.pallets.blue700} />
                                <Bar dataKey="totalArea" fill={dcolors.pallets.green700} />
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                </DRow>
            )}
            <DRow>
                <Box sx={{ marginBottom: 4, width: '100%', height: 400 }}>
                    <Typography variant="h6" sx={{ marginBottom: 2 }}>{`Total Number of Cracks and Total Crack Area per Phase`}</Typography>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            data={crackDataPerPhase}
                            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="phase" />
                            <YAxis />
                            <Tooltip formatter={(value: number, name: string) => name === 'totalArea' ? `${value} sq ft` : value} />
                            <Legend formatter={(value: string) => value === 'totalArea' ? 'Total Area (sq ft)' : 'Total Cracks'} />
                            <Bar dataKey="totalCracks" fill={dcolors.pallets.blue700} />
                            <Bar dataKey="totalArea" fill={dcolors.pallets.green700} />
                        </BarChart>
                    </ResponsiveContainer>
                </Box>
            </DRow>
            <DRow>
                <Box sx={{ marginBottom: 4, width: '100%', height: 400 }}>
                    <Typography variant="h6" sx={{ marginBottom: 2 }}>{`Total Number of Cracks per Floor per Phase`}</Typography>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            data={sortedFloors.map(floor => ({
                                floor,
                                ...phases.reduce((acc, phase, index) => {
                                    acc[phase] = crackDataPerFloor[floor][phase]?.totalCracks || 0;
                                    return acc;
                                }, {} as { [key: string]: number })
                            }))}
                            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="floor" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            {phases.map((phase, index) => (
                                <Bar key={phase} dataKey={phase} fill={phaseColors[index % phaseColors.length]} />
                            ))}
                        </BarChart>
                    </ResponsiveContainer>
                </Box>
            </DRow>

        </DResponsiveWindow>
    );
}
