import { dcolors } from "corexxx"
import React, { useState } from "react"
import PolygonDrawer from "../../admin/pages/PolygonDrawer"
import { DTextWithControl } from "../../algoverse/pages/Common"
import { useDAppCommand } from "../../libs/dweb/DAppCommand"
import { DBox } from "../../libs/dweb/DBox"
import { DButton } from "../../libs/dweb/DButton"
import { DFullScreenDialog } from "../../libs/dweb/DFullScreenDialog"
import { DRefreshButton } from "../../libs/dweb/DRefreshButton"
import { DResponsiveWindow } from "../../libs/dweb/DResponsiveWindow"
import { DRow } from "../../libs/dweb/DRow"
import { DSelectInput } from "../../libs/dweb/DSelectInput"
import { DTable } from "../../libs/dweb/DTable"
import { DTextDashboard } from "../../libs/dweb/DTextDashboard"
import { useDemo } from "../models/DemoHook"
export function extractNumber(filename: string) {
    let match = filename.match(/^(\d+)/);
    return match ? parseInt(match[0], 10) : Infinity;
}

export function AdminSummaryPage() {
    let hook = useDemo()
    let [curProject, setCurProject] = useState<string>('')
    let [curPhase, setCurPhase] = useState<string>('')
    let [currentIdx, setCurrentIdx] = useState<number>(0)
    let [open, setOpen] = useState(false)

    React.useEffect(() => {
        let keys = Object.keys(hook.state.projectInfo)
        if (keys.length > 0) {
            setCurProject(keys[0])
        }
    }, [hook.state.projectInfo])

    function goToNextImage() {
        if (currentIdx < hook.state.rawImageManifest.length - 1) {
            setCurrentIdx(currentIdx + 1)
        }
    }
    function goToPreviousPage() {
        if (currentIdx > 0) {
            setCurrentIdx(currentIdx - 1)
        }
    }

    return <DResponsiveWindow>
        <DRow style={{ flex: 0, marginTop: 20, marginBottom: 20 }}>
            {hook.state.projectInfo[curProject] ? <DTextDashboard
                dark={true}
                itemStyle={{ background: dcolors.pallets.blue700, margin: 10, padding: 20 }}
                valueStyle={{ fontSize: 32 }}
                item={[
                    {
                        text: 'Project',
                        value: curProject || ''
                    },
                    {
                        text: 'Total Image',
                        value: (hook.state.projectInfo[curProject].total_image || 0) + ''
                    },
                    {
                        text: 'Progress on detection',
                        value: `${hook.state.projectInfo[curProject].total_process_count}/${hook.state.projectInfo[curProject].total_image}`,
                        sub_text: `${(hook.state.projectInfo[curProject].total_process_count / hook.state.projectInfo[curProject].total_image * 100).toFixed(2)}% completed`
                    },
                    {
                        text: 'Defective Images',
                        value: `${hook.state.projectInfo[curProject].image_with_crash_count}/${hook.state.projectInfo[curProject].total_image}`,
                        sub_text: `${(hook.state.projectInfo[curProject].image_with_crash_count / hook.state.projectInfo[curProject].total_image * 100).toFixed(2)}% of the images has crack`
                    },
                    {
                        text: 'Number of Cracks',
                        value: `${hook.state.projectInfo[curProject].total_crack_count}`,

                    }
                ]} /> : null}
        </DRow>
        <DBox>
            <DTextWithControl text="Summary Table" rightJsx={<>
                <DSelectInput
                    style={{ width: 200, marginRight: 10 }}
                    label="Select Project"
                    value={curProject} options={Object.keys(hook.state.projectInfo).map(x => ({ text: x, value: x }))} onChange={setCurProject} />

                <DSelectInput
                    style={{ width: 200, marginRight: 10 }}
                    label="Select phase"
                    value={curPhase} options={
                        Object.keys(hook.state.projectInfo[curProject]?.phaseList || {}).map(x => ({ text: x, value: x }))
                    } onChange={setCurPhase} />
                <DButton text="Annotattion" onClick={() => setOpen(true)} style={{ marginRight: 10 }} />
                <DRefreshButton onClick={hook.api.loadManifest} />
            </>} />
            {hook.state.projectInfo[curProject]?.phaseList[curPhase]?.imgList ?
                (<DTable
                    data={hook.state.projectInfo?.[curProject]?.phaseList[curPhase]?.imgList}
                    style={{ boxShadow: 'none', border: 'none' }}
                    columns={[
                        { field: "_id" },
                        { field: "image_name" },
                        {
                            field: "crack_detected", format(arg, obj) {
                                return `${(obj.crack_annotation?.length || 0) > 0} (count: ${obj.crack_annotation?.length || 0})`
                            },
                        },
                        { field: "is_processed" },
                    ]}
                    actions={[
                        {
                            text: 'Anotation', onClick: (obj, idx) => {
                                setCurrentIdx(idx || 0);
                                setOpen(true)
                            }
                        }
                    ]}

                />) : null}

        </DBox>

        {hook.state.projectInfo[curProject]?.phaseList[curPhase]?.imgList ? (
            <DFullScreenDialog open={open} onClose={() => setOpen(false)} content={<>
                <PolygonDrawer
                    entry_id={hook.state.projectInfo[curProject]?.phaseList[curPhase]?.imgList?.[currentIdx]?._id || ''}
                    callback={{
                        onNext: goToNextImage,
                        onPrev: goToPreviousPage
                    }}
                />
            </>
            } />) : null}
    </DResponsiveWindow>
}

