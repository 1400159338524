import { Button, CircularProgress } from "@mui/material";
import { TVoidCalBack } from "corexxx";
import { CSSProperties } from "react";
import { useDAppCommand } from "./DAppCommand";

export function DButton({ text, onClick, icon, style, loading }: { text: string, onClick: TVoidCalBack, icon?: JSX.Element, style?: CSSProperties, loading?: boolean }) {
    let appCommand = useDAppCommand()
    // Note that either you pass onClick directly or need to handle async
    return (<Button disabled={loading} size="small" variant="contained" onClick={onClick} style={{ backgroundColor: appCommand.getAppConfig()?.primary_color, ...style }}>
        {loading ? <CircularProgress color="inherit" size={14} style={{ marginRight: 5, color: '#fffff99' }} /> : null}
        <span style={{ marginRight: 3, marginTop: 0, fontSize: 5 }}>{icon}</span>
        {text}
    </Button>)
}