import { dlog } from "corexxx";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import PolygonDrawer from "../admin/pages/PolygonDrawer";
import { SimpleStoreClient } from "../libs/common_ts/SimpleStoreClient";
import { DefaultAccountScreen } from "../libs/components/screens/DefaultAccountScreen";
import { DefaultPageInProgress } from "../libs/components/screens/DefaultPageInProgress";
import { TAppConfig, setAppConfig } from "../libs/dweb/DAppCommand";
import { DAppInit } from "../libs/dweb/DAppInit";
import { DAppWrapper } from "../libs/dweb/DAppWrapper";
import { DHelmet } from "../libs/dweb/DHelmet";
import { CrackLandingPage } from "./pages/CrackLandingPage";
import { DashBoardPage } from "./pages/DashboardPage";

//import "../data/converter";
//import { DashBoard } from '../merit/DashBoard';

// Step1: Init
let appConfig: TAppConfig = {
  app_id: "grodok_demo",
  app_name: "Grodok Cracks",
  primary_color: '#240b36',
  app_slogan: 'Grodok crack detection techology.',
  app_subtext: 'Grodok crack detection techology.',
  logo: require('./focus/favicon_io/android-chrome-512x512.png'),
  simpleStoreEndpoint: 'https://simplestore.grodok.com',
  firebase_config: {
    apiKey: "AIzaSyDOcNLkMpZgO810xhjW5XeihzUHFdwR9Xc",
    authDomain: "crackdetection-4ed7b.firebaseapp.com",
    projectId: "crackdetection-4ed7b",
    storageBucket: "crackdetection-4ed7b.appspot.com",
    messagingSenderId: "31315038169",
    appId: "1:31315038169:web:a14dcfa9de0e86f20c13c8",
    measurementId: "G-3QGX308RD7"
  }
}
SimpleStoreClient.Get().init(appConfig)
setAppConfig(appConfig);

function App() {
  return (
    <RecoilRoot>
      <DHelmet config={{ page_title: "Home Page" }} />
      <DAppWrapper>
        <BrowserRouter>
          <DAppInit appConfig={appConfig} />
          <Routes>
            <Route path="/" element={<CrackLandingPage />} />
            <Route path="/account" element={<DefaultAccountScreen />} />
            <Route path="/dashboard" element={<DashBoardPage />} />
            <Route path="/tool" element={<div style={{ width: '100vw', height: '100vh' }}><PolygonDrawer
              entry_id="668dcc23e7ad2a4b0df1591f"
              style={{ background: 'black' }}
              callback={{
                onNext: () => {
                  dlog.d("onNext called")
                },
                onPrev: () => {
                  dlog.d(" onPrev called")
                }
              }}
            /> </div>} />
            <Route path="*" element={<DefaultPageInProgress />} />
          </Routes>
        </BrowserRouter>
      </DAppWrapper>
    </RecoilRoot>
  );
}

export default App;
