import { ArrowLeftRounded, ArrowRightRounded, GestureOutlined, Save, ZoomInOutlined, ZoomOutRounded } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import MenuIcon from '@mui/icons-material/Menu';
import { CircularProgress, IconButton } from '@mui/material';
import { dcolors, dlog, dnetwork, TVoidCalBack } from 'corexxx';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { TImageMetaInformation } from '../../demo/models/DemoHook';
import { DHook } from '../../libs/common_hooks/DHook';
import { useDAppCommand } from '../../libs/dweb/DAppCommand';
import { DRow } from '../../libs/dweb/DRow';
import { DTable } from '../../libs/dweb/DTable';
import { DText } from '../../libs/dweb/DText';
import { DTextTutorial } from '../../libs/dweb/DTextTutorial';
const base_url = 'https://intellicogno-s3.s3.us-east-2.amazonaws.com/images'
export type TPoint = { x: number, y: number }
export type TPolygon = { name: string, points: TPoint[] }
let isDrawProgress = false

const PolygonDrawer = ({ entry_id, style, callback }:
    {
        style?: CSSProperties,
        entry_id: string,
        extraMenuButton?: any,
        callback?: {
            onNext?: TVoidCalBack,
            onPrev?: TVoidCalBack
        }
    }) => {

    const appCommand = useDAppCommand()
    const [entry, setEntry] = useState<TImageMetaInformation | undefined>()
    const imageCanvasRef = useRef<any>(null);
    const overlayCanvasRef = useRef<any>(null);
    const [mode, setMode] = useState<'ZOOM' | 'DRAW'>('ZOOM');
    const [points, setPoints] = useState<TPoint[]>([]);
    const [completedPolygons, setCompletedPolygons] = useState<TPolygon[]>([]);
    const [scale, setScale] = useState(1);
    const [scaleX, setScaleX] = useState(1);
    const [cursorStyle, setCursorStyle] = useState('default');
    const [image, setImage] = useState<any>()
    const [sidePanel, setSidePanel] = useState(true);

    DHook.useAsyncEffect(async () => {
        appCommand.api.safeCall(async () => {
            let data = await dnetwork.getSimpleStore(`${appCommand.state.simpleStoreEndpoint}/api/remote_nodel_grodok_cracks/?_id=${entry_id}`)
            setEntry(data.out[0])
        })
    }, [entry_id])



    function drawInBackCanvas() {
        const imageCanvas = imageCanvasRef.current;
        const imageContext = imageCanvas?.getContext('2d')
        const overlayCanvas = overlayCanvasRef.current;
        const overlayContext = overlayCanvas?.getContext('2d');
        if (!image || !imageContext || !overlayContext) {
            return;
        }

        //1. set width and height:
        let canvasWidth = 0
        let canvasHeight = 0;
        let scale = 1;
        {
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;
            const scaleWidth = windowWidth / image.width;
            const scaleHeight = windowHeight / image.height;

            // find scale
            scale = Math.min(scaleWidth, scaleHeight) * scaleX;

            dlog.d(`scale: ${scale}, scaleX: ${scaleX} org: ${image.width}x${image.height}`)
            canvasWidth = image.width * scale;
            canvasHeight = image.height * scale;

            imageCanvasRef.current.width = canvasWidth;
            imageCanvasRef.current.height = canvasHeight;
            overlayCanvasRef.current.width = canvasWidth;
            overlayCanvasRef.current.height = canvasHeight;
            const container = imageCanvasRef.current.parentElement;
            container.style.width = (canvasWidth + 4) + 'px';
            container.style.height = (canvasHeight + 4) + 'px';
            setScale(scale);
        }

        // 2. clear the react and draw the image
        {
            imageContext.clearRect(0, 0, overlayCanvasRef.current.width, overlayCanvasRef.current.height);
            imageCanvasRef.current?.getContext('2d').drawImage(image, 0, 0, overlayCanvasRef.current.width, overlayCanvasRef.current.height);
        }

        // 3. Drawing the lines
        {
            completedPolygons.forEach(polygon => {
                imageContext.beginPath();
                imageContext.moveTo(polygon.points[0].x * scale, polygon.points[0].y * scale);
                polygon.points.forEach(point => {
                    imageContext.lineTo(point.x * scale, point.y * scale);
                });
                imageContext.closePath();
                imageContext.strokeStyle = 'red';
                imageContext.lineWidth = 2;
                imageContext.stroke();
                imageContext.fillStyle = 'rgba(255,0,0,0.1)';
                imageContext.fill();
            });
        }
    }

    useEffect(() => {
        if (!entry) {
            return;
        }
        setImage(null)
        setPoints([])
        setCompletedPolygons(entry.crack_annotation || [])
        setMode('ZOOM')
        setScaleX(1)
        const image = new Image();
        let imageUrl = `${base_url}/marenabeach/${entry.phase}/${entry.image_name}`
        image.src = imageUrl;
        image.onload = () => {
            setImage(image)
        }
    }, [entry]);

    useEffect(() => {
        drawInBackCanvas()
    }, [scaleX, completedPolygons, image])

    // listing to mode to set right cursor style + clear points
    useEffect(() => {
        setCursorStyle(mode == 'ZOOM' ? 'pointer' : 'crosshair')
        setPoints([])
    }, [mode])

    // when make first click - save that point
    const handleSingleClick = (event: any) => {
        if (!image) {
            return;
        }

        if (mode != 'DRAW') {
            return;
        }
        isDrawProgress = true;
        if (!image) return;
        const canvas = overlayCanvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        setPoints((prevPoints) => [...prevPoints, { x, y }]);
    };

    // when make double click - end the drawing
    const handleDoubleClick = () => {
        if (!image) {
            return;
        }
        isDrawProgress = false;
        if (points.length > 1) {
            setCompletedPolygons((prevPolygons) => [...prevPolygons, { name: 'polygon', points: points.map(i => ({ x: i.x / scale, y: i.y / scale })) }]);
            setPoints([]);
        }
        //setMode('ZOOM')
        cleanOverlay();
    };

    function cleanOverlay() {
        let overlayContext = overlayCanvasRef.current?.getContext('2d')
        if (!overlayContext) {
            return;
        }
        overlayContext.clearRect(0, 0, overlayCanvasRef.current.width, overlayCanvasRef.current.height);

    }

    // Function to handle mouse move event
    const handleMouseMove = (event: any) => {
        if (!image) {
            return;
        }
        if (!isDrawProgress) {
            return;
        }
        const canvas = overlayCanvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        let overlayContext = overlayCanvasRef.current?.getContext('2d')
        if (!overlayContext) {
            return;
        }

        // 1. Clear the overlay canvas
        cleanOverlay();
        // 2. Draw all previous lines. 
        if (points.length > 0) {
            overlayContext.beginPath();
            overlayContext.moveTo(points[0].x, points[0].y);
            points.forEach((point) => {
                overlayContext.lineTo(point.x, point.y);
            });
        }
        // 3. Draw the last live lines
        overlayContext.lineTo(x, y)

        // 4. Daw a line from (x,y) to first one.
        overlayContext.lineTo(points[0].x, points[0].y)
        // draw 
        overlayContext.strokeStyle = 'red';
        overlayContext.lineWidth = 2;
        overlayContext.stroke();
    }

    // handle zoom when use ctrl key
    const handleWheel = (event: React.WheelEvent) => {
        if (!image) {
            return;
        }
        if (event.ctrlKey || event.shiftKey || event.altKey) {
            event.preventDefault();
            const newScale = scaleX - event.deltaY * 0.001;
            if (newScale < 0.1) return;

            setScaleX(newScale);
            //event.preventDefault();
        }
    };

    // donwload annotation data
    const downloadAnnotation = () => {
        const json = JSON.stringify({ ...entry, 'crack_annotation': completedPolygons });
        const blob = new Blob([json], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${entry?.project_name}_${entry?.phase}_${entry?.image_name}.json`;
        a.click();
        URL.revokeObjectURL(url);
    }

    // saving data in SS
    const saveData = async () => {
        appCommand.api.safeCall(async () => {
            let data = await dnetwork.postSimpleStore(`${appCommand.state.simpleStoreEndpoint}/api/remote_nodel_grodok_cracks/update`, {
                _id: entry_id,
                crack_annotation: completedPolygons,
                crack_detected: completedPolygons.length > 0,
                is_processed: true,
                processed_date: new Date().toISOString(),
                processed_by: appCommand.accountState?.username
            })
            dlog.obj(data)
        })
    }

    // keyboard shortcuts
    useEffect(() => {
        const handleKeyDown = async (event: KeyboardEvent) => {
            if ((event.ctrlKey || event.metaKey || event.shiftKey || event.altKey)) {
                event.preventDefault();
                if (event.key === 's') {
                    await saveData();
                }
                else if (event.key === 'n') {
                    await saveData();
                    callback?.onNext?.();
                }
                else if (event.key === 'p') {
                    await saveData();
                    callback?.onPrev?.();
                }
                else if (event.key === 'm') {
                    setMode(mode == 'ZOOM' ? 'DRAW' : 'ZOOM')
                }
                else if (event.key === 'd') {
                    setMode('DRAW')
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
        // Not that they canpture by context , so we need to attach and detch the callback when tey called
    }, [entry, entry_id, image, completedPolygons, mode]);

    return (
        <div className="pd1" style={{ position: 'relative', height: "100%", width: '100%', ...style }}>
            <div style={{ position: 'relative', width: sidePanel ? `calc(100% - 400px)` : '100%', height: '100%', overflow: 'auto' }}>
                <div style={{ position: 'absolute', display: 'inline-block', border: '2px solid, black', top: 0, width: '100%', height: '100%' }}>
                    <canvas ref={imageCanvasRef} style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }} />
                    <canvas
                        ref={overlayCanvasRef}
                        onClick={handleSingleClick}
                        onDoubleClick={handleDoubleClick}
                        onMouseMove={handleMouseMove}
                        onWheel={handleWheel}
                        style={{ position: 'absolute', top: 0, left: 0, zIndex: 2, cursor: cursorStyle }}
                    />

                </div>
                {!image ? (<div style={{ background: 'white', zIndex: 2, position: 'absolute', display: 'inline-block', border: '2px solid, black', top: 0, width: '100%', height: '100%' }}>
                    <CircularProgress size={100} style={{ position: 'absolute', top: '50%', left: '50%', color: 'red', zIndex: 4 }} />
                </div>) : null}
            </div>

            <div style={{ position: 'absolute', height: '100%', width: 400, right: 0, top: 0, background: 'black', zIndex: 4, display: sidePanel ? 'block' : 'none' }}>
                <DText dark style={{ textAlign: 'center', fontSize: 30, borderBottom: '1px dashed white', padding: 10 }}>{entry?.phase}/{entry?.image_name}</DText>
                <DRow style={{ padding: 10, borderBottom: 10 }}>
                    <IconButton title="Click to draw" onClick={() => setMode('DRAW')}><GestureOutlined style={{ color: 'white' }} /></IconButton>
                    <IconButton title="Zoom in" onClick={() => { setScaleX(scaleX * 1.1) }}><ZoomInOutlined style={{ color: 'white' }} /></IconButton>
                    <IconButton title="Zoom out" onClick={() => { setScaleX(scaleX * 0.9) }}><ZoomOutRounded style={{ color: 'white' }} /></IconButton>
                    <IconButton title="Download Annotation" onClick={downloadAnnotation}><DownloadIcon style={{ color: 'white' }} /></IconButton>
                    <IconButton title="Save" onClick={saveData}><Save style={{ color: 'white' }} /></IconButton>
                    <IconButton title="Next" onClick={async () => {
                        await saveData();
                        callback?.onNext?.()
                    }}><ArrowRightRounded style={{ color: 'white' }} /></IconButton>
                    <IconButton title="Previous" onClick={async () => {
                        await saveData();
                        callback?.onPrev?.()
                    }
                    }><ArrowLeftRounded style={{ color: 'white' }} /></IconButton>

                </DRow>
                <DText dark style={{ padding: 10 }}>Total Annotation: {completedPolygons?.length}/ {entry?.crack_annotation?.length}</DText>
                <DTable
                    style={{ margin: 10, width: 'calc(100% - 20px)', background: dcolors.pallets.yellow50, marginBottom: 20 }}
                    data={completedPolygons}
                    columns={[
                        { field: 'name' }
                    ]}
                    inline_buttons_actions={[
                        {
                            'text': 'delete',
                            onClick: (obj, index) => {
                                setCompletedPolygons(completedPolygons.filter((x, idx) => idx != index))
                            }
                        }
                    ]}
                />
                <DTextTutorial data={
                    [
                        "CTRL + d : moved to drawing mode",
                        "CTRL + wheel  : Zoom in and Zoom out",
                        "CTRL + s  : Save annotation",
                        "CTRL + n  : Move to next picture( not saved)",
                        "CTRL + p  : Move to previous picture",
                        "CTRL + m  : switching between the mode",
                    ]
                } />
                <DTextTutorial
                    title="Image Information"
                    data={
                        [
                            `Image Loaded: ${image ? "true" : 'false'}`,
                            `completedPolygons Count: ${completedPolygons.length}`,
                            `Scale: ${scale.toFixed(2)}`,
                            `Image Size: ${image?.width}x${image?.height}`,
                            `Canvus Size: ${imageCanvasRef.current?.width}x${imageCanvasRef.current?.height}`,
                        ]
                    } />
            </div>

            <IconButton
                onClick={() => {
                    setSidePanel(!sidePanel)
                }}
                style={{ position: 'fixed', bottom: 45, right: 5, color: 'white', zIndex: 5 }}><MenuIcon></MenuIcon></IconButton>
        </div >
    );
};

export default PolygonDrawer;
