import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, CircularProgress, IconButton, ImageListItem, Popover, Typography } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DAlert } from '../../libs/dweb/DAlert';
import { DBox } from '../../libs/dweb/DBox';
import { DResponsiveWindow } from '../../libs/dweb/DResponsiveWindow';
import { DRow } from '../../libs/dweb/DRow';
import { DSelectInput } from "../../libs/dweb/DSelectInput";
import { useDemo } from "../models/DemoHook";

const base_url = 'https://intellicogno-s3.s3.us-east-2.amazonaws.com/images';

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
    image: {
        maxWidth: '100%',
        height: 'auto',
        cursor: 'pointer',
        position: 'relative'
    },
    watermark: {
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(255, 0, 0, 0.5)',
        color: 'white',
        padding: '3px',
        zIndex: 1,
        fontSize: '.4rem',
    },
    highResImage: {
        maxWidth: '100%',
        maxHeight: '80vh',
    },
    imageListContainer: {
        display: 'flex',
        overflowX: 'auto',
        width: '100%',
        whiteSpace: 'nowrap'
    },
    imageListItem: {
        display: 'inline-block',
        width: 'auto',
    },
    arrowButton: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 2,
        color: 'white',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
    },
    leftArrow: {
        left: 10,
    },
    rightArrow: {
        right: 10,
    },
    floorLabel: {
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        padding: '5px',
        zIndex: 1,
        marginRight: '10px', // Add some space between the label and the images
    }
}));

interface ImageData {
    file_name: string;
    low_res_url: string;
    high_res_url: string;
    marked_url: string;
    crack_detected: boolean;
    floor?: number;
    crack_annotation?: {
        name: string;
        points: { x: number, y: number }[];
    }[]; // Annotation data
}

export function ImageViewPage() {
    const hook = useDemo();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [itemData, setItemData] = useState<ImageData[]>([]);
    const [projectName, setProjectName] = useState<string>('');
    const [phaseName, setPhaseName] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [numCols, setNumCols] = useState<number>(9);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [zoomLevel, setZoomLevel] = useState<number>(1);
    const [dragging, setDragging] = useState<boolean>(false);
    const [position, setPosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
    const [lastPosition, setLastPosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const isDrawingRef = useRef(false);

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setZoomLevel(1);
        setPosition({ x: 0, y: 0 });
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>, item: ImageData) => {
        setAnchorEl(document.body);
        setCurrentIndex(itemData.indexOf(item));
        setZoomLevel(1);
        setPosition({ x: 0, y: 0 });
        requestAnimationFrame(() => {
            if (item.crack_detected) {
                drawImageWithAnnotations(item);
            } else {
                drawImage(item);
            }
        });
    };

    const handlePhaseNameChange = (value: string) => {
        setPhaseName(value);
        const columns = hook.state.projectInfo[projectName]?.phaseList[value]?.number_of_column;
        setNumCols(columns);
    };

    const handleNext = () => {
        const newIndex = (currentIndex + 1) % itemData.length;
        setCurrentIndex(newIndex);
        setZoomLevel(1);
        setPosition({ x: 0, y: 0 });
        requestAnimationFrame(() => {
            if (itemData[newIndex].crack_detected) {
                drawImageWithAnnotations(itemData[newIndex]);
            } else {
                drawImage(itemData[newIndex]);
            }
        });
    };

    const handlePrev = () => {
        const newIndex = (currentIndex - 1 + itemData.length) % itemData.length;
        setCurrentIndex(newIndex);
        setZoomLevel(1);
        setPosition({ x: 0, y: 0 });
        requestAnimationFrame(() => {
            if (itemData[newIndex].crack_detected) {
                drawImageWithAnnotations(itemData[newIndex]);
            } else {
                drawImage(itemData[newIndex]);
            }
        });
    };

    const handleZoomIn = useCallback((event: React.WheelEvent | React.MouseEvent) => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const rect = canvas.getBoundingClientRect();
        const offsetX = event.clientX - rect.left;
        const offsetY = event.clientY - rect.top;
        setPosition((prevPosition) => ({
            x: prevPosition.x - (offsetX / zoomLevel) * 0.5,
            y: prevPosition.y - (offsetY / zoomLevel) * 0.5,
        }));
        setZoomLevel((prevZoomLevel) => Math.min(prevZoomLevel + 0.5, 10));
        requestAnimationFrame(() => {
            if (itemData[currentIndex].crack_detected) {
                drawImageWithAnnotations(itemData[currentIndex]);
            } else {
                drawImage(itemData[currentIndex]);
            }
        });
    }, [currentIndex, itemData, zoomLevel]);

    const handleZoomOut = useCallback((event: React.WheelEvent | React.MouseEvent) => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const rect = canvas.getBoundingClientRect();
        const offsetX = event.clientX - rect.left;
        const offsetY = event.clientY - rect.top;
        setPosition((prevPosition) => ({
            x: prevPosition.x + (offsetX / zoomLevel) * 0.5,
            y: prevPosition.y + (offsetY / zoomLevel) * 0.5,
        }));
        setZoomLevel((prevZoomLevel) => Math.max(prevZoomLevel - 0.5, 1));
        requestAnimationFrame(() => {
            if (itemData[currentIndex].crack_detected) {
                drawImageWithAnnotations(itemData[currentIndex]);
            } else {
                drawImage(itemData[currentIndex]);
            }
        });
    }, [currentIndex, itemData, zoomLevel]);

    const handleMouseDown = (event: React.MouseEvent) => {
        setDragging(true);
        setLastPosition({
            x: event.clientX,
            y: event.clientY,
        });
    };

    const handleMouseMove = (event: React.MouseEvent) => {
        if (dragging) {
            const sensitivity = 7;
            const deltaX = (event.clientX - lastPosition.x) * sensitivity;
            const deltaY = (event.clientY - lastPosition.y) * sensitivity;
            setPosition((prevPosition) => ({
                x: prevPosition.x + deltaX,
                y: prevPosition.y + deltaY,
            }));
            setLastPosition({
                x: event.clientX,
                y: event.clientY,
            });
            if (!isDrawingRef.current) {
                isDrawingRef.current = true;
                requestAnimationFrame(() => {
                    if (itemData[currentIndex].crack_detected) {
                        drawImageWithAnnotations(itemData[currentIndex]);
                    } else {
                        drawImage(itemData[currentIndex]);
                    }
                    isDrawingRef.current = false;
                });
            }
        }
    };

    const handleMouseUp = () => {
        setDragging(false);
    };

    const handleMouseLeave = () => {
        setDragging(false);
    };

    const handleWheel = (event: React.WheelEvent) => {
        if (event.deltaY < 0) {
            handleZoomIn(event);
        } else {
            handleZoomOut(event);
        }
    };

    const downloadFiles = async () => {
        try {
            setLoading(true);

            const response = hook.state.projectInfo[projectName]?.phaseList[phaseName];
            const data = response.imgList;
            const floorMapping = response.floor_mapping;

            const imagesMap = new Map();
            const insertIntoFilename = (filename: string, insert: string) => {
                const dotIndex = filename.lastIndexOf('.');
                if (dotIndex === -1) return filename;
                const name = filename.substring(0, dotIndex);
                const extension = filename.substring(dotIndex);
                return `${name}${insert}${extension}`;
            };

            data.forEach((file: { image_name: string, crack_detected: boolean, crack_annotation?: { name: string, points: { x: number, y: number }[] }[] }) => {
                const baseFileName = file.image_name;
                if (!imagesMap.has(baseFileName)) {
                    imagesMap.set(baseFileName, {
                        file_name: baseFileName,
                        low_res_url: "",
                        high_res_url: "",
                        crack_detected: file.crack_detected,
                        crack_annotation: file.crack_annotation || [],
                        floor: undefined
                    });
                }

                const imageEntry = imagesMap.get(baseFileName);
                const lowResURL = insertIntoFilename(file.image_name, '_low')

                imageEntry.low_res_url = `${base_url}/${projectName}/${phaseName}/${lowResURL}`;
                imageEntry.high_res_url = `${base_url}/${projectName}/${phaseName}/${file.image_name}`;
            });

            const validImages = Array.from(imagesMap.values()).filter(image => image.low_res_url && image.high_res_url);

            validImages.sort((a, b) => {
                const aNum = parseInt(a.file_name.match(/\d+/)?.[0] || "0", 10);
                const bNum = parseInt(b.file_name.match(/\d+/)?.[0] || "0", 10);
                return aNum - bNum;
            });

            floorMapping.forEach(mapping => {
                const [start, end] = mapping.range;
                for (let i = start; i <= end; i++) {
                    if (validImages[i]) {
                        validImages[i].floor = mapping.floor;
                    }
                }
            });

            setItemData(validImages);
        } catch (error) {
            console.error('Error uploading files:', error);
        } finally {
            setLoading(false);
        }
    };

    const drawImageWithAnnotations = useCallback((image: ImageData) => {
        const canvas = canvasRef.current;
        const ctx = canvas?.getContext('2d');

        if (!canvas || !ctx) {
            console.error('Canvas or context is null');
            return;
        }

        const img = new Image();
        img.src = image.high_res_url;

        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.save();
            ctx.scale(zoomLevel, zoomLevel);
            ctx.translate(position.x / zoomLevel, position.y / zoomLevel);
            ctx.drawImage(img, 0, 0);

            if (image.crack_detected && image.crack_annotation) {
                ctx.strokeStyle = 'red';
                ctx.lineWidth = 30 / zoomLevel;
                ctx.fillStyle = 'blue';
                ctx.font = `bold ${100 / zoomLevel}px Arial`;

                image.crack_annotation.forEach(annotation => {
                    if (annotation.name === 'polygon' && annotation.points) {
                        let minX = Infinity, maxX = -Infinity, minY = Infinity, maxY = -Infinity;

                        ctx.beginPath();
                        annotation.points.forEach((point, index) => {
                            if (index === 0) {
                                ctx.moveTo(point.x, point.y);
                            } else {
                                ctx.lineTo(point.x, point.y);
                            }

                            if (point.x < minX) minX = point.x;
                            if (point.x > maxX) maxX = point.x;
                            if (point.y < minY) minY = point.y;
                            if (point.y > maxY) maxY = point.y;
                        });
                        ctx.closePath();
                        ctx.stroke();

                        const width = maxX - minX;
                        const height = maxY - minY;

                        // const text = `W: ${width.toFixed(0)}px\nH: ${height.toFixed(0)}px`;
                        // ctx.fillText(text, minX, minY - 20);
                    }
                });
            }

            ctx.restore();
        };

        img.onerror = (error) => {
            console.error('Image loading error:', error);
        };
    }, [zoomLevel, position]);

    const drawImage = useCallback((image: ImageData) => {
        const canvas = canvasRef.current;
        const ctx = canvas?.getContext('2d');

        if (!canvas || !ctx) {
            console.error('Canvas or context is null');
            return;
        }

        const img = new Image();
        img.src = image.high_res_url;

        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.save();
            ctx.scale(zoomLevel, zoomLevel);
            ctx.translate(position.x / zoomLevel, position.y / zoomLevel);
            ctx.drawImage(img, 0, 0);
            ctx.restore();
        };

        img.onerror = (error) => {
            console.error('Image loading error:', error);
        };
    }, [zoomLevel, position]);

    useEffect(() => {
        if (projectName && phaseName && numCols) {
            downloadFiles();
        }
    }, [projectName, phaseName, numCols]);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const renderImageRow = (rowImages: ImageData[], rowIndex: number) => {
        const floor = rowImages[0]?.floor ?? '';

        return (
            <Box key={rowIndex} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2" className={classes.floorLabel}>
                    {floor}
                </Typography>
                <Box className={classes.imageListContainer}>
                    {rowImages.map((item) => (
                        <Box key={item.file_name} sx={{ position: 'relative', display: 'inline-block' }}>
                            <ImageListItem className={classes.imageListItem}>
                                <img
                                    src={item.low_res_url}
                                    alt={item.file_name}
                                    className={classes.image}
                                    onClick={(event) => handleClick(event, item)}
                                />
                                {item.crack_detected && (
                                    <div className={classes.watermark}>C</div>
                                )}
                            </ImageListItem>
                        </Box>
                    ))}
                </Box>
            </Box>
        );
    };

    return (
        <DResponsiveWindow>
            {Object.keys(hook.state.projectInfo).length == 0 ? <DAlert notification={{ type: 'error', msg: 'Either you have no project setup in your account or access is not yet granted.Please talk to admin to access the grant' }} /> : null}
            <DBox style={{ flex: 0, marginTop: 20 }}>
                <DRow >
                    <DSelectInput
                        style={{ width: 200, marginRight: 10 }}
                        label="Select Project"
                        value={projectName} options={Object.keys(hook.state.projectInfo).map(x => ({ text: x, value: x }))} onChange={setProjectName} />
                    <DSelectInput
                        style={{ width: 200, marginRight: 10 }}
                        label="Select phase"
                        value={phaseName} options={
                            Object.keys(hook.state.projectInfo[projectName]?.phaseList || {}).map(x => ({ text: x, value: x }))
                        } onChange={handlePhaseNameChange} />
                </DRow>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box>
                        {Array.from({ length: Math.ceil(itemData.length / numCols) }, (_, rowIndex) => {
                            const rowImages = itemData.slice(rowIndex * numCols, (rowIndex + 1) * numCols);
                            return renderImageRow(rowImages, rowIndex);
                        })}
                        <Popover
                            id={id}
                            open={open}
                            anchorReference="anchorPosition"
                            anchorPosition={{ top: window.innerHeight / 2, left: window.innerWidth / 2 }}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'center'
                            }}
                            transformOrigin={{
                                vertical: 'center',
                                horizontal: 'center'
                            }}
                            PaperProps={{
                                style: {
                                    width: '80%',
                                    height: '80%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    overflow: 'hidden',
                                },
                            }}
                        >
                            <Box
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={handleMouseUp}
                                onMouseLeave={handleMouseLeave}
                                onWheel={handleWheel}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    overflow: 'hidden',
                                    cursor: dragging ? 'grabbing' : 'grab',
                                }}
                            >
                                <canvas ref={canvasRef} className={classes.highResImage}></canvas>
                            </Box>
                            <IconButton
                                className={`${classes.arrowButton} ${classes.leftArrow}`}
                                onClick={handlePrev}
                            >
                                <ArrowBackIosIcon sx={{ fontSize: 30, color: "black" }} />
                            </IconButton>
                            <IconButton
                                className={`${classes.arrowButton} ${classes.rightArrow}`}
                                onClick={handleNext}
                            >
                                <ArrowForwardIosIcon sx={{ fontSize: 30, color: "black" }} />
                            </IconButton>
                            <Box sx={{
                                position: 'absolute',
                                bottom: '10px',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                <Button onClick={(event) => handleZoomOut(event)} disabled={zoomLevel <= 1}>-</Button>
                                <Typography variant="body2" style={{ margin: '0 10px' }}>{zoomLevel.toFixed(1)}x</Typography>
                                <Button onClick={(event) => handleZoomIn(event)} disabled={zoomLevel >= 10}>+</Button>
                            </Box>
                        </Popover>
                    </Box>
                )}
            </DBox>
        </DResponsiveWindow>
    );
}
